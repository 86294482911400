import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79f400b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic-theme" }
const _hoisted_2 = { class: "container d-flex justify-content-center align-items-center" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-center align-items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "image-cover" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "size-3 fw-bold mt-3 mb-1"
}
const _hoisted_8 = {
  key: 2,
  class: "size-6 text-center mt-1"
}
const _hoisted_9 = { class: "d-grid gap-2 w-100 mt-5" }
const _hoisted_10 = {
  key: 0,
  class: ""
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "size-6 mb-2"
}
const _hoisted_13 = { class: "size-6" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card p-3",
        style: _normalizeStyle([_ctx.templateForegroundColor, _ctx.templateFontColor])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.profileImage.status !== 'deleted')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    src: _ctx.profileImage.image
                  }, null, 8, _hoisted_6)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.videoName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.videoName), 1))
            : _createCommentVNode("", true),
          (_ctx.videoDescription)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.videoDescription), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoItems, (item, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                (item.videoUrl)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (item.label)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.label), 1))
                        : _createCommentVNode("", true),
                      (item.description)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.description), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("iframe", {
                          width: "100%",
                          src: item.videoUrl || '',
                          frameborder: "0",
                          allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                          allowfullscreen: ""
                        }, null, 8, _hoisted_14)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ])
      ], 4)
    ])
  ]))
}