import IStats from '@/models/IStats';
import IWebConfig from '@/models/IWebConfig';
import ApiBase from '@/services/ApiBaseService';

export default class StatsService extends ApiBase {
    constructor(webEnvConfig: IWebConfig) {
        super(webEnvConfig.urls?.webApi ?? '');
    }

    async saveStats(stats: IStats): Promise<void> {
        this.setIncludeAuthorization(false);
        this.addAcceptJsonHeader();
        await this.axiosInstance.post(`api/v1/stats/insert`, stats);
    }

    async incrementClick(urlId: string): Promise<void> {
        this.setIncludeAuthorization(false);
        this.addAcceptJsonHeader();

        const requestParams = {
            id: urlId,
        };

        const query = this.buildQuery(requestParams);
        await this.axiosInstance.post(`api/v1/stats/add-click` + query);
    }

    async incrementScan(urlId: string): Promise<void> {
        this.setIncludeAuthorization(false);
        this.addAcceptJsonHeader();

        const requestParams = {
            id: urlId,
        };
        const query = this.buildQuery(requestParams);
        await this.axiosInstance.post(`api/v1/stats/add-scan` + query);
    }
}
