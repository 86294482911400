import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c500512"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dynamic-theme" }
const _hoisted_2 = { class: "container d-flex justify-content-center align-items-center" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-center align-items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "size-3 fw-bold mt-3"
}
const _hoisted_6 = {
  key: 2,
  class: "size-6 text-center mt-2"
}
const _hoisted_7 = { class: "gap-2 w-100 mt-2 text-center" }
const _hoisted_8 = {
  key: 0,
  class: "social-item"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "size-1" }
const _hoisted_11 = { class: "d-grid gap-2 w-100 mt-3" }
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card p-3",
        style: _normalizeStyle([_ctx.templateForegroundColor, _ctx.templateFontColor])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.profileImage.status !== 'deleted')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([`profile-shape-${_ctx.profileShape}-container`, _ctx.hasProfileBorder()])
              }, [
                _createElementVNode("img", {
                  src: _ctx.profileImage.image,
                  class: _normalizeClass([`profile-shape-${_ctx.profileShape}`, "profile-size"])
                }, null, 10, _hoisted_4)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.displayName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.displayName), 1))
            : _createCommentVNode("", true),
          (_ctx.displayDescription)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.displayDescription), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displaySocialLinks, (item, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                (item.url)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("a", {
                        href: item.url || undefined,
                        _target: "blank",
                        class: "text-center"
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("i", {
                            class: _normalizeClass(_ctx.SocialMedias.getIcon(item.platform))
                          }, null, 2)
                        ])
                      ], 8, _hoisted_9)
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayButtonLinks, (item, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                (item.label && item.url)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: item.url || undefined,
                      _target: "blank",
                      class: "btn",
                      style: _normalizeStyle([_ctx.buttonBackgroundColor, _ctx.buttonBorderType, _ctx.buttonBorderRadius, _ctx.buttonBorderWidth, _ctx.buttonBorderColor, _ctx.buttonFontColor])
                    }, _toDisplayString(item.label), 13, _hoisted_12))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ])
      ], 4)
    ])
  ]))
}