
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';
import Utils from '@/utils/Utils';
import FileUtils from '@/utils/File';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            mobilePhone: '',
            homePhone: '',
            websiteUrl: '',
            personalAddress: '',
            personalAddress1: '',
            personalCity: '',
            personalState: '',
            personalZip: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
            companyName: '',
            companyWebsiteUrl: '',
            companyAddress: '',
            companyAddress1: '',
            companyCity: '',
            companyState: '',
            companyZip: '',
            jobTitle: '',
            companyEmail: '',
            companyPhone1: '',
            companyFax: '',
            templateForegroundColor: '',
            templateFontColor: '',
            buttonBackgroundColor: '',
            buttonBorderColor: '',
            buttonBorderType: '',
            buttonBorderRadius: '',
            buttonBorderWidth: '',
            buttonFontColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
        this.setButtonBackgroundColor();
        this.setButtonBorderRadius();
        this.setButtonBorderWidth();
        this.setButtonBorderType();
        this.setButtonBorderColor();
        this.setButtonFontColor();
    },
    methods: {
        onload() {
            this.firstName = this.selectedQRCode?.url?.data?.firstName || '';
            this.lastName = this.selectedQRCode?.url?.data?.lastName || '';
            this.email = this.selectedQRCode?.url?.data?.email || '';
            this.mobilePhone = this.selectedQRCode?.url?.data?.mobilePhone || '';
            this.homePhone = this.selectedQRCode?.url?.data?.homePhone || '';
            this.websiteUrl = this.selectedQRCode?.url?.data?.websiteUrl || '';

            this.personalAddress1 = this.selectedQRCode?.url?.data?.personalAddress1 || '';
            this.personalCity = this.selectedQRCode?.url?.data?.personalAddressCity || '';
            this.personalState = this.selectedQRCode?.url?.data?.personalAddressState || '';
            this.personalZip = this.selectedQRCode?.url?.data?.personalAddressZip || '';
            this.personalAddress = ThemeUtils.formatAddress(this.personalAddress1, this.personalCity, this.personalState, this.personalZip);

            this.companyAddress1 = this.selectedQRCode?.url?.data?.companyAddress1 || '';
            this.companyCity = this.selectedQRCode?.url?.data?.companyAddressCity || '';
            this.companyState = this.selectedQRCode?.url?.data?.companyAddressState || '';
            this.companyZip = this.selectedQRCode?.url?.data?.companyAddressZip || '';
            this.companyAddress = ThemeUtils.formatAddress(this.companyAddress1, this.companyCity, this.companyState, this.companyZip);

            this.companyName = this.selectedQRCode?.url?.data?.companyName || '';
            this.companyWebsiteUrl = this.selectedQRCode?.url?.data?.companyWebsiteUrl || '';
            this.companyEmail = this.selectedQRCode?.url?.data?.companyEmail || '';
            this.companyPhone1 = this.selectedQRCode?.url?.data?.companyPhone1 || '';
            this.companyFax = this.selectedQRCode?.url?.data?.companyFax || '';

            this.jobTitle = this.selectedQRCode?.url?.data?.jobTitle || '';

            if (this.selectedQRCode?.url?.data?.profile) {
                this.profileImage = {
                    status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                    image: this.selectedQRCode?.url?.data?.profile?.image,
                };
            } else {
                this.profileImage = {
                    status: 'deleted',
                    image: '',
                };
            }

            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape || 'circle-1';
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border || true;
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        prettyURL(url: string) {
            return Utils.prettyURL(url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
        setButtonBackgroundColor() {
            this.buttonBackgroundColor = ThemeUtils.getButtonBackgroundColor(this.selectedQRCode?.url);
        },
        setButtonFontColor() {
            this.buttonFontColor = ThemeUtils.getButtonFontColor(this.selectedQRCode?.url);
        },
        setButtonBorderType() {
            this.buttonBorderType = ThemeUtils.getButtonBorderType(this.selectedQRCode?.url);
        },
        setButtonBorderRadius() {
            this.buttonBorderRadius = ThemeUtils.getButtonBorderRadius(this.selectedQRCode?.url);
        },
        setButtonBorderWidth() {
            this.buttonBorderWidth = ThemeUtils.getButtonBorderWidth(this.selectedQRCode?.url);
        },
        setButtonBorderColor() {
            this.buttonBorderColor = ThemeUtils.getButtonBorderColor(this.selectedQRCode?.url);
        },
        async download() {
            const vcard = {
                version: '3.0',
                firstName: this.firstName || 'No',
                lastName: this.lastName || 'Name',
                photo: this.profileImage.image,
                title: this.jobTitle,
                company: this.companyName,
                phone: {
                    home: this.homePhone,
                    work: this.companyPhone1,
                    cell: this.mobilePhone,
                    fax: this.companyFax,
                },
                email: {
                    home: this.email,
                    work: this.companyEmail,
                },
                url: {
                    home: this.websiteUrl,
                    work: this.companyWebsiteUrl,
                },
                location: {
                    home: {
                        address: this.personalAddress1,
                        city: this.personalCity,
                        state: this.personalState,
                        zip: this.personalZip,
                    },
                    work: {
                        address: this.companyAddress1,
                        city: this.companyCity,
                        state: this.companyState,
                        zip: this.companyZip,
                    },
                },
            };

            const content = await ThemeUtils.vCardOutput(vcard);
            const mimeType = 'text/vcard';
            const filename = `${this.firstName}-${this.lastName}.vcf`;

            FileUtils.fileDownload(content, mimeType, filename);
        },
    },
});
