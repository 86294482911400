
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import TinyUrlManager from '@/managers/TinyUrlManager';

import AppStore from '@/components/QRCode/DynamicPreviewer/AppStoreTheme/AppStore.vue';
import BusinessPage from '@/components/QRCode/DynamicPreviewer/BusinessPageTheme/BusinessPage.vue';
import Coupon from '@/components/QRCode/DynamicPreviewer/CouponTheme/Coupon.vue';
import Event from '@/components/QRCode/DynamicPreviewer/EventTheme/Event.vue';
import Image from '@/components/QRCode/DynamicPreviewer/ImageTheme/Image.vue';
import Menu from '@/components/QRCode/DynamicPreviewer/MenuTheme/Menu.vue';
import PDF from '@/components/QRCode/DynamicPreviewer/PDFTheme/PDF.vue';
import SocialLinks from '@/components/QRCode/DynamicPreviewer/SocialLinksTheme/SocialLinks.vue';
import VCard from '@/components/QRCode/DynamicPreviewer/VCardTheme/VCard.vue';
import Video from '@/components/QRCode/DynamicPreviewer/VideoTheme/Video.vue';

import TinyPage from '@/components/TinyPage/DynamicPreviewer/TinyPageTheme/TinyPage.vue';

import { storeWatcher } from '@/utils/StoreWatcher';
import { qrCodeStore } from '@/store';
import _ from 'lodash';
import Expired from './Expired.vue';
import Collections from '@/utils/Collections';

export default defineComponent({
    name: 'Proxy',
    components: { Expired, 'app-store': AppStore, 'business-page': BusinessPage, coupon: Coupon, event: Event, 'image-page': Image, 'menu-page': Menu, pdf: PDF, 'social-links': SocialLinks, 'tiny-page': TinyPage, vcard: VCard, 'video-page': Video },
    data() {
        return {
            message: '',
            loadingScreen: {
                show: true,
            },
            dynamicComponent: {
                show: false,
                component: null,
            },
        };
    },
    setup() {
        const route = useRoute();
        const currentRouteQuery = route.query;
        const currentRoute = computed(() => {
            return route.params.catchAll;
        });

        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            currentRoute,
            currentRouteQuery,
            settings,
            selectedQRCode,
        };
    },
    created() {
        this.settings.watch('SelectedQRCodeTimestamp', () => {
            const result = computed(() => this.settings.SelectedQRCode);
            this.selectedQRCode = _.cloneDeep(result.value);
            this.initialize();
        });
    },
    async mounted() {
        await TinyUrlManager.fetchTinyUrl(this.currentRoute.toString());
    },
    methods: {
        async initialize() {
            if (Collections.isObjectEmpty(this.selectedQRCode?.url) || this.selectedQRCode?.url?.typeId === '') {
                this.badRedirect();
            } else if (this.selectedQRCode?.url?.typeId === 'tiny-url' || this.selectedQRCode?.url?.typeId === 'url') {
                if (await this.processTinyPackage()) {
                    TinyUrlManager.redirect(this.selectedQRCode);
                }
            } else if (this.selectedQRCode?.url?.typeId === 'tiny-page') {
                if (await this.processTinyPackage()) {
                    this.swapComponent(this.selectedQRCode?.url?.typeId);
                }
            } else if (this.selectedQRCode?.url?.qrId && this.selectedQRCode?.url?.typeId) {
                if (await this.processTinyPackage()) {
                    // Vue warning when call components with built-in or reserved HTML elements as component
                    const reserved = ['image', 'menu', 'video'];
                    const typeId = Collections.isValueInArray(reserved, this.selectedQRCode?.url?.typeId) ? `${this.selectedQRCode?.url?.typeId}-page` : this.selectedQRCode?.url?.typeId;

                    this.swapComponent(typeId);
                }
            }
        },
        async processTinyPackage(): Promise<boolean> {
            if (!this.selectedQRCode) return false;
            if (!this.selectedQRCode?.url?._id) return false;

            if (!TinyUrlManager.canAccess(this.selectedQRCode)) {
                TinyUrlManager.saveStats(this.selectedQRCode?.url?._id);
                const message = TinyUrlManager.hasExpirationMessage(this.selectedQRCode);
                this.expirationMessage(message);
            } else {
                if ((this.selectedQRCode?.url?.typeId === 'tiny-url' || this.selectedQRCode?.url?.typeId === 'url') && this.selectedQRCode?.url?.data?.url) {
                    await TinyUrlManager.saveStats(this.selectedQRCode?.url?._id);
                    await TinyUrlManager.saveCount(this.selectedQRCode?.url?._id, this.currentRouteQuery);
                    return true;
                } else if ((this.selectedQRCode?.url?.typeId === 'tiny-page' || this.selectedQRCode?.url?.qrId) && this.selectedQRCode?.url?.data) {
                    TinyUrlManager.saveStats(this.selectedQRCode?.url?._id);
                    TinyUrlManager.saveCount(this.selectedQRCode?.url?._id, this.currentRouteQuery);
                    return true;
                } else {
                    this.badRedirect();
                }
            }
            return false;
        },
        badRedirect() {
            this.$router.push('404');
        },
        expirationMessage(message: string) {
            this.message = message;

            // Would like to do this but params are passing as undefined
            //this.$router.push({ name: 'Expired', params: { message: message } });
        },
        swapComponent(component: any) {
            this.loadingScreen.show = false;
            this.dynamicComponent.component = component;
            this.dynamicComponent.show = true;
        },
    },
});
