export default interface ITinyUrl {
    _id: string;
    typeId?: string;
    qrId?: string;
    data?: Data;
    domain?: string;
    code?: string;
    isActive?: boolean;
    dateExpiration?: string | null;
    expirationMessage?: string | null;
    dateDeleted?: string | null;
}

export interface Data {
    [name: string]: any;
}

export function newTinyUrl(): ITinyUrl {
    const options: ITinyUrl = {
        _id: '',
        typeId: '',
        qrId: '',
        data: {},
        domain: '',
        code: '',
        dateExpiration: null,
        expirationMessage: null,
        dateDeleted: null,
        isActive: false,
    };
    return options;
}
