export default interface IStats {
    urlId: string;
    client: any;
    os: any;
    device: any;
    referrer: any;
    bot: any;
}

export function newStats(): IStats {
    const options: IStats = {
        urlId: '',
        client: {},
        os: {},
        device: {},
        referrer: {},
        bot: {},
    };
    return options;
}
