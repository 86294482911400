import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Proxy from '../views/Proxy.vue';
import Expired from '../views/Expired.vue';
import Error from '../views/404.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:catchAll(.*)',
        component: Proxy,
    },
    {
        path: '/expired',
        name: 'Expired',
        component: Expired,
        props: {
            default: true,
        },
    },
    {
        path: '/404',
        name: '404',
        component: Error,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
