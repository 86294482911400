import { LocalStorage } from '@/constants/LocalStorage';
import ITokenData from '@/models/ITokenData';

export class theLocalStorage {
    get(key: string): string | null {
        if (this.hasKey(key)) {
            try {
                const parsed = JSON.parse(localStorage.getItem(key) || '{}');

                if (typeof parsed !== 'number') {
                    return parsed;
                } else {
                    return String(parsed);
                }
            } catch (e) {
                return localStorage.getItem(key);
            }
        } else {
            return null;
        }
    }

    hasKey(key: string): boolean {
        return localStorage.getItem(key) !== null;
    }

    hasKeyAndValue(key: string): boolean {
        if (!this.hasKey(key)) {
            return false;
        }

        const value = localStorage.getItem(key);
        return value ? true : false;
    }

    save(key: string, value: string | undefined): void {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    saveTokenData(tokenData: ITokenData): void {
        this.save(LocalStorage.at, JSON.stringify(tokenData));
    }
}

const theLocalStorageUtils = new theLocalStorage();
export default theLocalStorageUtils;
