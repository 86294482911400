import IWebConfig from '@/models/IWebConfig';
import ApiBase from '@/services/ApiBaseService';
import IResponse from '@/models/IResponse';

export default class UploadService extends ApiBase {
    constructor(webEnvConfig: IWebConfig) {
        super(webEnvConfig.urls?.webApi ?? '');
    }

    async convertURLToBase64Encoded(s3Path: string): Promise<IResponse> {
        const result: IResponse = {};
        this.setIncludeAuthorization(false);
        this.addAcceptJsonHeader();

        const requestParams = {
            path: s3Path,
        };
        const query = this.buildQuery(requestParams);
        const response = await this.axiosInstance.get('api/v1/storage' + query);
        if (response.status === 200) {
            result.message = response.data.message || {};
            result.data = response.data || {};
        }
        return result;
    }
}
