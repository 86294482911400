import QRCode from '@/models/IQRCode';
import { newQRCode } from '@/models/IQRCode';
import { qrCodeStore } from '@/store';

export default {
    async getSelectedQRCode(): Promise<QRCode> {
        return qrCodeStore.SelectedQRCode || newQRCode();
    },

    async setSelectedQRCode(qrCode: QRCode) {
        qrCodeStore.setSelectedQRCode(qrCode);
        qrCodeStore.updateSelectedQRCode();
    },
};
