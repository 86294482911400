type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type ReadonlyKeys<T> = {
    [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, never, P>;
}[keyof T];

export type WithWatchableGetters<T> = {
    watch<Key extends string & keyof Pick<T, ReadonlyKeys<T>>>(getter: `${Key}`, callback: (newValue: T[Key]) => void, options?: any): void;
};

export function storeWatcher<T>(module: T): T & WithWatchableGetters<T> {
    function watchFn(getter: string, callback: (newVal: any) => void, options: any) {
        const getterFunc = (module as any).__lookupGetter__(getter);
        const nativeWatcher = (
            module as any as {
                store: {
                    watch: (g: string, cb: (newVal: any) => void, o: any) => void;
                };
            }
        ).store.watch;

        nativeWatcher.apply((module as any).store, [getterFunc, callback, options]);
    }

    if (!(module as any).watcherSet) {
        (module as any).watcherSet = true;
        (module as any).watch = watchFn;
    }

    return <T & WithWatchableGetters<T>>module;
}
