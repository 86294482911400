import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';

import '@/assets/style/imports.scss';
import LaunchManager from './managers/LaunchManager';

const launchManager = LaunchManager.getInstance();
launchManager.prepare().then(() => {
    const app = createApp(App).use(store).use(router);
    app.mount('#app');
});
