import WebConfig from '@/services/WebConfigService';
import UploadService from '@/services/UploadService';

export default {
    async convertURLToBase64Encoded(url: string): Promise<string> {
        const webConfig = await WebConfig.getInstance().getConfigByHost();
        const service = new UploadService(webConfig);
        const response = await service.convertURLToBase64Encoded(url);
        if (response.data) {
            return response.data;
        }

        return '';
    },
};
