export default {
    urlToBase64(url: string) {
        const fetchBased64 = (url: RequestInfo) =>
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => readFile(blob));

        return fetchBased64(url).then((base64) => {
            return base64;
        });
    },
    fileToBase64(file: Blob) {
        const fetchBased64 = (file: Blob) => readFile(file);
        return fetchBased64(file).then((base64) => {
            return base64;
        });
    },
    bytesToSize(bytes: number) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        //@ts-ignore
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        //@ts-ignore
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    fileDownload(content: string, mimeType: string, filename: string) {
        // const a = document.createElement('a');
        // const blob = new Blob([content], { type: mimeType });
        // const url = URL.createObjectURL(blob);
        // a.setAttribute('href', url);
        // a.setAttribute('download', filename);
        // a.click();

        const a = document.createElement('a');
        const file = new Blob([content], { type: mimeType });
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
        URL.revokeObjectURL(a.href);
    },
};

function readFile(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}
