import IStats from '@/models/IStats';
import Utils from './Utils';

const deviceDetector = require('device-detector-js');

export default {
    async setBrowserInfo(): Promise<IStats> {
        const parsedUserAgentData = parseUserAgent(window.navigator.userAgent);
        const referrerData = {}; // this gets populate when saving stat
        const stats = { ...parsedUserAgentData, ...referrerData };
        return stats;
    },

    async parseReferrer(url: string) {
        let href = 'Direct';
        let hostname = 'Direct';

        const parsed = Utils.parseURL(url);
        if (url) {
            href = parsed.href || 'Direct';
            hostname = parsed.hostname || 'Direct';
        }

        const urlData = {
            referrer: href,
            hostname: hostname,
        };
        return urlData;
    },
};

function parseUserAgent(userAgent: string) {
    const detector = new deviceDetector();
    return detector.parse(userAgent);
}
