export default {
    isValueInArray(arr: any, value: any): boolean {
        return arr.indexOf(value) !== -1;
    },
    isKeyInObject(obj: any, key: any): boolean {
        return Object.keys(obj).some((v) => v == key);
    },
    isObjectEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0 ? true : false;
    },
    objectSize(obj: any): number {
        return Object.keys(obj).length;
    },
    cleanObjectEmptyValue(obj: any) {
        for (const propName in obj) {
            if (!obj[propName]) {
                delete obj[propName];
            }
        }
        return obj;
    },
    shuffleObject(obj: any) {
        for (let i = this.objectSize(obj) - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = obj[i];
            obj[i] = obj[j];
            obj[j] = tmp;
        }
        return obj;
    },
    randomObject(obj: any) {
        const newObject = this.shuffleObject(obj);
        return newObject[0];
    },
    sortByProperty(sort: string, name: string) {
        return function (a: any, b: any) {
            if (sort === 'asc') {
                return a[name] > b[name] ? 1 : -1;
            } else if (sort === 'desc') {
                return a[name] < b[name] ? 1 : -1;
            }

            return 0;
        };
    },
    returnArray1AsObject(array: any) {
        if (Array.isArray(array) && array.length === 1) {
            if (typeof array[0] === 'object' && !Array.isArray(array[0]) && array[0] !== null) {
                return array[0];
            }
        }
        return array;
    },
    addItemArray(arr: any, value: any): any {
        if (arr.indexOf(value) === -1) {
            arr.push(value);
        }
        return arr;
    },
    removeItemArray(arr: any, value: any): any {
        if (arr.indexOf(value) !== -1) {
            arr.splice(arr.indexOf(value), 1);
        }
        return arr;
    },
    removeItemArrayByIndex(arr: any, idx: number): any {
        arr.splice(idx, 1);
        return arr;
    },
    findItemArrayByValueAndUpdate(arr: any, curValue: any, newValue: any): any {
        if (arr.indexOf(curValue) !== -1) {
            arr[arr.indexOf(curValue)] = newValue;
        }
        return arr;
    },
};
