import IQRCode, { newQRCode } from '@/models/IQRCode';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import _ from 'lodash';

@Module({ namespaced: true, name: 'qrcode' })
export default class QRCodeStore extends VuexModule {
    private selectedQRCode: IQRCode | null = null;
    private selectedQRCodeTimestamp = new Date();

    get SelectedQRCodeTimestamp() {
        return this.selectedQRCodeTimestamp;
    }

    get SelectedQRCode() {
        return this.selectedQRCode;
    }

    @Mutation
    updateSelectedQRCode() {
        this.selectedQRCodeTimestamp = new Date();
    }

    @Mutation
    setSelectedQRCode(qrCode: IQRCode) {
        this.selectedQRCode = qrCode;
    }

    @Mutation
    resetSelectedQRCode() {
        this.selectedQRCode = newQRCode();
    }
}
