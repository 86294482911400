const list = [
    {
        label: 'Biking',
        icon: 'fa-solid fa-person-biking',
    },
    {
        label: 'Bar',
        icon: 'fa-solid fa-martini-glass',
    },
    {
        label: 'Boats',
        icon: 'fa-solid fa-sailboat',
    },
    {
        label: 'Camping',
        icon: 'fa-solid fa-campground',
    },
    {
        label: 'Gym / Health Club',
        icon: 'fa-solid fa-dumbbell',
    },
    {
        label: 'Kid-friendly',
        icon: 'fa-solid fa-child-reaching',
    },
    {
        label: 'Hiking',
        icon: 'fa-solid fa-person-hiking',
    },
    {
        label: 'Hunting',
        icon: 'fa-solid fa-crosshairs',
    },
    {
        label: 'Lodging',
        icon: 'fa-solid fa-bed',
    },
    {
        label: 'Parking',
        icon: 'fa-solid fa-square-parking',
    },
    {
        label: 'Pet-friendly',
        icon: 'fa-solid fa-dog',
    },
    {
        label: 'Restaurant',
        icon: 'fa-solid fa-utensils',
    },
    {
        label: 'Restrooms',
        icon: 'fa-solid fa-restroom',
    },
    {
        label: 'Shopping',
        icon: 'fa-solid fa-bag-shopping',
    },
    {
        label: 'Spa',
        icon: 'fa-solid fa-spa',
    },
    {
        label: 'Swimming',
        icon: 'fa-solid fa-person-swimming',
    },
    {
        label: 'Wifi',
        icon: 'fa-solid fa-wifi',
    },
];

export default {
    getIcon(text: string): string {
        for (let i = 0; i < list.length; i++) {
            const label = list[i].label;
            const icon = list[i].icon;

            if (text === label) {
                return icon;
            }
        }
        return 'fa-solid fa-mobile-screen-button';
    },
};
