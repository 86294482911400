import TinyUrlManager from './TinyUrlManager';

export default class LaunchManager {
    private static instance: LaunchManager;

    private constructor() {
        //constructor
    }

    static getInstance(): LaunchManager {
        if (!this.instance) {
            this.instance = new LaunchManager();
        }
        return this.instance;
    }

    async prepare(): Promise<void> {
        await this.detectBrowser();
    }

    private async detectBrowser(): Promise<void> {
        await TinyUrlManager.setBroswerInfo();
    }
}
