
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            displayName: '',
            displayDescription: '',
            displayEmail1: '',
            displayItems: [],
            templateForegroundColor: '',
            templateFontColor: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
    },
    methods: {
        onload() {
            this.displayName = this.selectedQRCode?.url?.data?.displayName || '';
            this.displayDescription = this.selectedQRCode?.url?.data?.displayDescription || '';
            this.displayEmail1 = this.selectedQRCode?.url?.data?.displayEmail1 || '';
            this.displayItems = this.selectedQRCode?.url?.data?.files ? this.selectedQRCode?.url?.data?.files : '';

            if (this.selectedQRCode?.url?.data?.profile) {
                this.profileImage = {
                    status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                    image: this.selectedQRCode?.url?.data?.profile?.image,
                };
            } else {
                this.profileImage = {
                    status: 'deleted',
                    image: '',
                };
            }

            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape || 'circle-1';
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border || true;
        },
        enlarge(item: string) {
            const modal = document.getElementById('imgModal') as HTMLDivElement;
            const modalImg = document.getElementById('imgEnlarged') as HTMLImageElement;
            const close = document.getElementsByClassName('image-thumbnail-close')[0] as HTMLSpanElement;
            modal.style.display = 'block';
            modalImg.src = item;

            close.onclick = function () {
                modal.style.display = 'none';
            };
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
    },
});
