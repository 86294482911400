import IQRCode from '@/models/IQRCode';
import StatsService from '@/services/StatsService';
import TinyUrl from '@/services/TinyUrl';
import WebConfig from '@/services/WebConfigService';
import { detectBrowserStore } from '@/store';
import DateTimeUtils from '@/utils/DateTimeUtils';
import DetectBrowser from '@/utils/DetectedDevice';
import QRCodeManager from './QRCodeManager';
import DetectedDevice from '@/utils/DetectedDevice';

export default {
    async fetchTinyUrl(code: string) {
        const webConfig = await WebConfig.getInstance().getConfigByHost();
        const service = new TinyUrl(webConfig);
        const result = await service.fetchTinyUrl(code);

        await QRCodeManager.setSelectedQRCode({ url: result });
    },

    async setBroswerInfo() {
        const result = await DetectBrowser.setBrowserInfo();
        detectBrowserStore.setDetectBrowser(result);
        detectBrowserStore.updateTimestamp();
    },

    async updateReferrer(payload: any) {
        detectBrowserStore.updateReferrer(payload);
        detectBrowserStore.updateTimestamp();
    },

    async resetState() {
        detectBrowserStore.resetDetectBrowser();
        detectBrowserStore.updateTimestamp();
    },

    canAccess(selectedQRCode: IQRCode): boolean {
        let isActive = false;
        let isExpired = false;
        let isDeleted = false;

        isActive = this.isActive(selectedQRCode);
        isExpired = this.isExpired(selectedQRCode);
        isDeleted = this.isDeleted(selectedQRCode);

        if (isActive && !isExpired && !isDeleted) {
            return true;
        }

        return false;
    },

    isDeleted(selectedQRCode: IQRCode): boolean {
        if (selectedQRCode?.url?.dateDeleted !== null) {
            return true;
        }

        return false;
    },

    isExpired(selectedQRCode: IQRCode): boolean {
        if (selectedQRCode?.url?.dateExpiration !== null) {
            return this.hasExpired(selectedQRCode?.url?.dateExpiration);
        }

        return false;
    },

    isActive(selectedQRCode: IQRCode): boolean {
        if (selectedQRCode?.url?.isActive) {
            return true;
        }

        return false;
    },

    hasExpired(expiration: string | undefined): boolean {
        if (expiration !== null) {
            return DateTimeUtils.compareDateToNow(expiration);
        }
        return false;
    },

    hasExpirationMessage(selectedQRCode: IQRCode): string {
        const defaultMessage = 'The page you are looking for is unavailable.';
        if (selectedQRCode?.url?.expirationMessage !== null) {
            return selectedQRCode?.url?.expirationMessage || defaultMessage;
        }
        return defaultMessage;
    },

    async redirect(selectedQRCode: IQRCode): Promise<void> {
        const webConfig = await WebConfig.getInstance().getConfigByHost();
        window.location.href = `${webConfig.urls?.webApi}api/v1/tiny-url/redirect?uri=${selectedQRCode?.url?.data?.url}`;
    },

    async saveStats(urlId: string) {
        const webConfig = await WebConfig.getInstance().getConfigByHost();
        const statsService = new StatsService(webConfig);

        const referrer = await DetectedDevice.parseReferrer(document.referrer);
        detectBrowserStore.updateReferrer(referrer);
        detectBrowserStore.updateUrlId(urlId);

        const stats = detectBrowserStore.DetectBrowser;
        if (stats && urlId) {
            await statsService.saveStats(stats);
        }
    },

    async saveCount(urlId: string, routeQuery: any): Promise<void> {
        const webConfig = await WebConfig.getInstance().getConfigByHost();
        const statsService = new StatsService(webConfig);
        if (!routeQuery?.p) {
            await statsService.incrementClick(urlId);
        } else if (routeQuery?.p === 'qr') {
            await statsService.incrementScan(urlId);
        } else {
            // invaild
        }
    },
};
