import IStats, { newStats } from '@/models/IStats';
import _ from 'lodash';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'detectbrowser' })
export default class DetectBrowserStore extends VuexModule {
    private browserInfo: IStats | null = null;
    private timestamp = new Date();

    get DetectBrowser(): IStats | null {
        return this.browserInfo;
    }

    get Timestamp() {
        return this.timestamp;
    }

    @Mutation
    updateTimestamp() {
        this.timestamp = new Date();
    }

    @Mutation
    setDetectBrowser(payload: IStats | null) {
        this.browserInfo = payload;
    }

    @Mutation
    updateReferrer(obj: any) {
        if (!this.browserInfo) return;

        const data = this.browserInfo?.referrer;
        if (data === undefined) {
            this.browserInfo.referrer = obj;
        } else {
            _.merge(data, obj);
        }
    }

    @Mutation
    updateUrlId(urlId: string) {
        if (!this.browserInfo) return;
        this.browserInfo.urlId = urlId;
    }

    @Mutation
    resetDetectBrowser() {
        this.browserInfo = newStats();
    }
}
