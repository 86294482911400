
import { defineComponent } from 'vue';

import Collection from '@/utils/Collections';
import Expressions from '@/constants/Expressions';

export default defineComponent({
    name: '404',
    data() {
        return {
            errorExpression: '',
        };
    },
    mounted() {
        this.expressionRandom();
    },
    methods: {
        expressionRandom() {
            const shuffledExpressions = Collection.shuffleObject(Expressions);
            this.errorExpression = shuffledExpressions[0];
        },
    },
});
