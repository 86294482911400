import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expired = _resolveComponent("Expired")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dynamicComponent.show)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dynamicComponent.component), {
          key: 0,
          "swap-component": _ctx.swapComponent
        }, null, 8, ["swap-component"]))
      : _createCommentVNode("", true),
    (_ctx.message !== '')
      ? (_openBlock(), _createBlock(_component_Expired, {
          key: 1,
          message: _ctx.message
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}