
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';

import Theme1 from '@/components/QRCode/DynamicPreviewer/ImageTheme/Themes/Theme1.vue';
import ThemeFooter from '@/components/ThemeFooter.vue';

import ThemeUtils from '@/utils/ThemeUtils';

export default defineComponent({
    name: 'Image',
    components: { Theme1, ThemeFooter },
    data() {
        return {
            selectedLayout: 1,
            templateBackgroundColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    mounted() {
        this.setTheme();
        this.setTemplateBackgroundColor();
    },
    created() {
        this.settings.watch('SelectedQRCodeTimestamp', () => {
            const result = computed(() => this.settings.SelectedQRCode);
            this.selectedQRCode = _.cloneDeep(result.value);
            this.setTemplateBackgroundColor();
        });
    },
    methods: {
        setTheme() {
            this.selectedLayout = this.selectedQRCode?.url?.data?.theme?.layout || 1;
        },
        setTemplateBackgroundColor() {
            document.body.style.cssText = ThemeUtils.getTemplateBackgroundColor(this.selectedQRCode?.url);
        },
    },
});
