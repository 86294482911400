
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';
import Utils from '@/utils/Utils';

import SocialMedias from '@/constants/SocialMedias';
import ITinyPage from '@/models/ITinyPage';
import ISocialLinks from '@/models/ISocialLinks';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            displayName: '',
            displayDescription: '',
            displayButtonLinks: [] as ITinyPage[],
            displaySocialLinks: [] as ISocialLinks[],
            templateForegroundColor: '',
            templateFontColor: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
            buttonBackgroundColor: '',
            buttonBorderColor: '',
            buttonBorderType: '',
            buttonBorderRadius: '',
            buttonBorderWidth: '',
            buttonFontColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
            SocialMedias,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
        this.setButtonBackgroundColor();
        this.setButtonBorderRadius();
        this.setButtonBorderWidth();
        this.setButtonBorderType();
        this.setButtonBorderColor();
        this.setButtonFontColor();
    },
    methods: {
        onload() {
            this.displayName = this.selectedQRCode?.url?.data?.displayName || '';
            this.displayDescription = this.selectedQRCode?.url?.data?.displayDescription || '';
            this.displayButtonLinks = this.selectedQRCode?.url?.data?.displayButtonLinks ? this.selectedQRCode?.url?.data?.displayButtonLinks : '';
            this.displaySocialLinks = this.selectedQRCode?.url?.data?.displaySocialLinks ? this.selectedQRCode?.url?.data?.displaySocialLinks : '';

            if (this.selectedQRCode?.url?.data?.profile) {
                this.profileImage = {
                    status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                    image: this.selectedQRCode?.url?.data?.profile?.image,
                };
            } else {
                this.profileImage = {
                    status: 'deleted',
                    image: '',
                };
            }

            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape || 'circle-1';
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border || true;
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
        prettyURL(url: string) {
            return Utils.prettyURL(url);
        },
        setButtonBackgroundColor() {
            this.buttonBackgroundColor = ThemeUtils.getButtonBackgroundColor(this.selectedQRCode?.url);
        },
        setButtonFontColor() {
            this.buttonFontColor = ThemeUtils.getButtonFontColor(this.selectedQRCode?.url);
        },
        setButtonBorderType() {
            this.buttonBorderType = ThemeUtils.getButtonBorderType(this.selectedQRCode?.url);
        },
        setButtonBorderRadius() {
            this.buttonBorderRadius = ThemeUtils.getButtonBorderRadius(this.selectedQRCode?.url);
        },
        setButtonBorderWidth() {
            this.buttonBorderWidth = ThemeUtils.getButtonBorderWidth(this.selectedQRCode?.url);
        },
        setButtonBorderColor() {
            this.buttonBorderColor = ThemeUtils.getButtonBorderColor(this.selectedQRCode?.url);
        },
    },
});
