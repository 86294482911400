
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            pdf: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.onload();
    },
    methods: {
        onload() {
            this.pdf = this.selectedQRCode?.url?.data?.file || '';
        },
    },
});
