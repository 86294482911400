import UploadManager from '@/managers/UploadManager';
import IURL from '@/models/IURL';

export default {
    getTemplateBackgroundColor(selectedTinyUrl: any): string {
        const colorOption = selectedTinyUrl?.data?.theme?.styling?.background?.colorOption ? selectedTinyUrl?.data?.theme?.styling?.background?.colorOption : 'gradient';
        const color1 = selectedTinyUrl?.data?.theme?.styling?.background?.colors[0] ? selectedTinyUrl?.data?.theme?.styling?.background?.colors[0] : '#b0b4b5';
        const color2 = selectedTinyUrl?.data?.theme?.styling?.background?.colors[1] ? selectedTinyUrl?.data?.theme?.styling?.background?.colors[1] : '#484e70';
        const gradientType = selectedTinyUrl?.data?.theme?.styling?.background?.gradientType ? selectedTinyUrl?.data?.theme?.styling?.background?.gradientType : 'radial';
        const gradientLinear = selectedTinyUrl?.data?.theme?.styling?.background?.gradientTypeLinear ? selectedTinyUrl?.data?.theme?.styling?.background?.gradientTypeLinear : 'top-down';
        return this.getCSSBackgroundColor(colorOption, color1, color2, gradientType, gradientLinear);
    },
    getTemplateForegroundColor(selectedTinyUrl: any): string {
        const colorOption = selectedTinyUrl?.data?.theme?.styling?.foreground?.colorOption ? selectedTinyUrl?.data?.theme?.styling?.foreground?.colorOption : 'single';
        const color1 = selectedTinyUrl?.data?.theme?.styling?.foreground?.colors[0] ? selectedTinyUrl?.data?.theme?.styling?.foreground?.colors[0] : '#fff';
        const color2 = selectedTinyUrl?.data?.theme?.styling?.foreground?.colors[1] ? selectedTinyUrl?.data?.theme?.styling?.foreground?.colors[1] : '#fff';
        const gradientType = selectedTinyUrl?.data?.theme?.styling?.foreground?.gradientType ? selectedTinyUrl?.data?.theme?.styling?.foreground?.gradientType : 'radial';
        const gradientLinear = selectedTinyUrl?.data?.theme?.styling?.foreground?.gradientTypeLinear ? selectedTinyUrl?.data?.theme?.styling?.foreground?.gradientTypeLinear : 'top-down';
        return this.getCSSBackgroundColor(colorOption, color1, color2, gradientType, gradientLinear);
    },
    getButtonBackgroundColor(selectedTinyUrl: any): string {
        const colorOption = selectedTinyUrl?.data?.theme?.styling?.button?.background?.colorOption ? selectedTinyUrl?.data?.theme?.styling?.button?.background?.colorOption : 'gradient';
        const color1 = selectedTinyUrl?.data?.theme?.styling?.button?.background?.colors[0] ? selectedTinyUrl?.data?.theme?.styling?.button?.background?.colors[0] : '#b0b4b5';
        const color2 = selectedTinyUrl?.data?.theme?.styling?.button?.background?.colors[1] ? selectedTinyUrl?.data?.theme?.styling?.button?.background?.colors[1] : '#484e70';
        const gradientType = selectedTinyUrl?.data?.theme?.styling?.button?.background?.gradientType ? selectedTinyUrl?.data?.theme?.styling?.button?.background?.gradientType : 'radial';
        const gradientLinear = selectedTinyUrl?.data?.theme?.styling?.button?.background?.gradientTypeLinear ? selectedTinyUrl?.data?.theme?.styling?.button?.background?.gradientTypeLinear : 'top-down';
        return this.getCSSBackgroundColor(colorOption, color1, color2, gradientType, gradientLinear);
    },
    getButtonBorderColor(selectedTinyUrl: any): string {
        const colorOption = selectedTinyUrl?.data?.theme?.styling?.button?.border?.colorOption ? selectedTinyUrl?.data?.theme?.styling?.button?.border?.colorOption : 'gradient';
        const color1 = selectedTinyUrl?.data?.theme?.styling?.button?.border?.colors[0] ? selectedTinyUrl?.data?.theme?.styling?.button?.border?.colors[0] : '#b0b4b5';
        const color2 = selectedTinyUrl?.data?.theme?.styling?.button?.border?.colors[1] ? selectedTinyUrl?.data?.theme?.styling?.button?.border?.colors[1] : '#484e70';
        const gradientType = selectedTinyUrl?.data?.theme?.styling?.button?.border?.gradientType ? selectedTinyUrl?.data?.theme?.styling?.button?.border?.gradientType : 'radial';
        const gradientLinear = selectedTinyUrl?.data?.theme?.styling?.button?.border?.gradientTypeLinear ? selectedTinyUrl?.data?.theme?.styling?.button?.border?.gradientTypeLinear : 'top-down';
        return this.getCSSBorderColor(colorOption, color1, color2, gradientType, gradientLinear);
    },
    getButtonBorderType(selectedTinyUrl: any): string {
        const type = selectedTinyUrl?.data?.theme?.styling?.button?.type ? selectedTinyUrl?.data?.theme?.styling?.button?.type : 'solid';
        return this.getCSSBorderType(type);
    },
    getButtonBorderRadius(selectedTinyUrl: any): string {
        const radius = selectedTinyUrl?.data?.theme?.styling?.button?.radius ? selectedTinyUrl?.data?.theme?.styling?.button?.radius : '5';
        return this.getCSSBorderRadius(radius);
    },
    getButtonBorderWidth(selectedTinyUrl: any): string {
        const width = selectedTinyUrl?.data?.theme?.styling?.button?.width ? selectedTinyUrl?.data?.theme?.styling?.button?.width : '1';
        return this.getCSSBorderWidth(width);
    },
    getTemplateFontColor(selectedTinyUrl: any): string {
        const color = selectedTinyUrl?.data?.theme?.styling?.font?.color ? selectedTinyUrl?.data?.theme?.styling?.font?.color : '#000000';
        return this.getFontCSS(color);
    },
    getButtonFontColor(selectedTinyUrl: any): string {
        const color = selectedTinyUrl?.data?.theme?.styling?.button?.fontColor ? selectedTinyUrl?.data?.theme?.styling?.button?.fontColor : '#000000';
        return this.getFontCSS(color);
    },
    getCSSBackgroundColor(colorOption: string, color1: string, color2: string, gradientType: string, gradientLinear: string): string {
        if (colorOption === 'gradient') {
            const gradientDirection = this.getGradientDirection(gradientLinear);
            if (gradientType === 'radial') {
                return this.getGradientRadialBackgroundCSS(color1, color2);
            } else {
                return this.getGradientLinearBackgroundCSS(gradientDirection, color1, color2);
            }
        } else if (colorOption === 'single') {
            return this.getSingleBackgroundCSS(color1);
        } else {
            return this.getTransparentBackgroundCSS();
        }
    },
    getCSSBorderColor(colorOption: string, color1: string, color2: string, gradientType: string, gradientLinear: string): string {
        if (colorOption === 'gradient') {
            const gradientDirection = this.getGradientDirection(gradientLinear);
            if (gradientType === 'radial') {
                return this.getGradientRadialBorderColorCSS(color1, color2);
            } else {
                return this.getGradientLinearBorderColorCSS(gradientDirection, color1, color2);
            }
        } else if (colorOption === 'single') {
            return this.getSingleBorderColorCSS(color1);
        } else {
            return this.getTransparentBorderColorCSS();
        }
    },
    getGradientDirection(gradientLinearDirection: string): string {
        let result = '90deg';
        switch (gradientLinearDirection) {
            case 'right-left':
                result = '270deg';
                break;
            case 'top-down':
                result = '180deg';
                break;
            case 'left-right':
                result = '90deg';
                break;
            case 'bottom-up':
                result = '0deg';
                break;
            default:
                result = '90deg';
        }

        return result;
    },
    getTransparentBackgroundCSS() {
        return `background: none`;
    },
    getSingleBackgroundCSS(color1: string): string {
        return `background: ${color1};`;
    },
    getGradientRadialBackgroundCSS(color1: string, color2: string): string {
        return `background: radial-gradient(circle, ${color1} 0%, ${color2} 100%);`;
    },
    getGradientLinearBackgroundCSS(gradientDirection: string, color1: string, color2: string): string {
        return `background: linear-gradient(${gradientDirection}, ${color1} 0%, ${color2} 100%);`;
    },
    getFontCSS(color1: string): string {
        return `color: ${color1};`;
    },
    getCSSBorderRadius(radius: string): string {
        return ` border-radius: ${radius}px;`;
    },
    getCSSBorderType(type: string): string {
        return ` border-style: ${type};`;
    },
    getCSSBorderWidth(width: string): string {
        return ` border-width: ${width}px;`;
    },
    getTransparentBorderColorCSS() {
        return `border-color: none`;
    },
    getSingleBorderColorCSS(color1: string): string {
        return `border-color: ${color1};`;
    },
    getGradientRadialBorderColorCSS(color1: string, color2: string): string {
        return `
        -moz-border-image: -moz-radial-gradient(circle, ${color1} 0%, ${color2} 100%);
        -webkit-border-image: -webkit-radial-gradient(circle, ${color1} 0%, ${color2} 100%);
        border-image: radial-gradient(circle, ${color1} 0%, ${color2} 100%);
        border-image-slice: 1;
        `;
    },
    getGradientLinearBorderColorCSS(gradientDirection: string, color1: string, color2: string): string {
        return `
        -moz-border-image: -moz-linear-gradient(${gradientDirection}, ${color1} 0%, ${color2} 100%);
        -webkit-border-image: -webkit-linear-gradient(${gradientDirection}, ${color1} 0%, ${color2} 100%);
        border-image: linear-gradient(${gradientDirection}, ${color1} 0%, ${color2} 100%);
        border-image-slice: 1;
        `;
    },
    formatAddress(address1: string, city: string, state: string, zip: string): string {
        let formatted = '';
        if (address1) {
            formatted = `<span>${address1}<span><br/>`;
        }

        if (city) {
            formatted += `<span>${city}<span>`;
            formatted += state ? ', ' : '';
        }

        if (state) {
            formatted += ` <span>${state}<span>`;
        }

        if (zip) {
            formatted += ` <span>${zip}<span>`;
        }

        return formatted;
    },
    async vCardOutput(vCard: any): Promise<string> {
        //https://datatracker.ietf.org/doc/id/draft-ietf-vcarddav-vcardrev-02.html
        let payload = `BEGIN:VCARD\n`;
        payload = vCard?.version !== '' ? `${payload}VERSION:${vCard?.version}\n` : `${payload}VERSION:4.0\n`;
        payload = vCard?.lastName !== '' && vCard?.firstName !== '' ? `${payload}N:${vCard?.lastName};${vCard?.firstName};;;\n` : payload;
        payload = vCard?.lastName !== '' && vCard?.firstName !== '' ? `${payload}FN:${vCard?.firstName} ${vCard?.lastName}\n` : payload;

        console.log('vCard', vCard);
        if (vCard?.photo && vCard?.photo !== '') {
            let type = '';
            let base64 = '';
            if (vCard?.photo && vCard?.photo.includes('base64')) {
                const base64Split = vCard?.photo.split(',');
                base64 = base64Split[1];
                const MIMEType = base64Split[0].split(':').pop().split(';')[0];
                type = MIMEType.split('/')[1]?.toUpperCase() || '';
            } else {
                const obj: IURL = new URL(vCard?.photo);
                if (obj) {
                    const path = obj?.pathname?.slice(1) || '';
                    type = path.split('.').pop() || '';
                    base64 = await UploadManager.convertURLToBase64Encoded(path);
                }
            }

            payload = vCard?.photo !== '' ? `${payload}PHOTO;ENCODING=BASE64;TYPE=${type}:${base64}\n` : payload;
        }

        // Phone
        payload = vCard?.phone.home !== '' ? `${payload}TEL;type=home:${vCard?.phone.home}\n` : payload;
        payload = vCard?.phone.cell !== '' ? `${payload}TEL;type=cell:${vCard?.phone.cell}\n` : payload;
        payload = vCard?.phone.work !== '' ? `${payload}TEL;type=work:${vCard?.phone.work}\n` : payload;
        payload = vCard?.phone.fax !== '' ? `${payload}TEL;TYPE=fax,work:${vCard?.phone.fax}\n` : payload;

        // Email
        payload = vCard?.email.home !== '' ? `${payload}EMAIL;type=home:${vCard?.email.home}\n` : payload;
        payload = vCard?.email.work !== '' ? `${payload}EMAIL;type=work:${vCard?.email.work}\n` : payload;

        // Url
        payload = vCard?.url.home !== '' ? `${payload}URL;type=home:${vCard?.url.home}\n` : payload;
        payload = vCard?.url.work !== '' ? `${payload}URL;type=work:${vCard?.url.work}\n` : payload;

        // Company
        payload = vCard?.company !== '' ? `${payload}ORG:${vCard?.company}\n` : payload;
        payload = vCard?.title !== '' ? `${payload}TITLE:${vCard?.title}\n` : payload;

        // Location
        payload = vCard?.location.home !== '' ? `${payload}ADR;type=home:;;${vCard?.location.home.address};${vCard?.location.home.city};${vCard?.location.home.state};${vCard?.location.home.zip}\n` : payload;
        payload = vCard?.location.work !== '' ? `${payload}ADR;type=work:;;${vCard?.location.work.address};${vCard?.location.work.city};${vCard?.location.work.state};${vCard?.location.work.zip}\n` : payload;

        payload = `${payload}END:VCARD\n`;

        return payload;
    },
};
