
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';
import Utils from '@/utils/Utils';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            companyName: '',
            websiteUrl: '',
            companyAddress: '',
            companyPhone1: '',
            companyPhone2: '',
            companyEmail1: '',
            companyEmail2: '',
            companyDescription: '',
            companyFacebook: '',
            companyTwitter: '',
            companyLinkedIn: '',
            companyInstagram: '',
            companyYoutube: '',
            companyPinterest: '',
            companyEtsy: '',
            companyShopify: '',
            companyTikTok: '',
            templateForegroundColor: '',
            templateFontColor: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
    },
    methods: {
        onload() {
            this.companyName = this.selectedQRCode?.url?.data?.companyName || '';
            this.websiteUrl = this.selectedQRCode?.url?.data?.websiteUrl || '';

            const companyAddress1 = this.selectedQRCode?.url?.data?.companyAddress1 || '';
            const companyCity = this.selectedQRCode?.url?.data?.companyAddressCity || '';
            const companyState = this.selectedQRCode?.url?.data?.companyAddressState || '';
            const companyZip = this.selectedQRCode?.url?.data?.companyAddressZip || '';
            this.companyAddress = ThemeUtils.formatAddress(companyAddress1, companyCity, companyState, companyZip);

            this.companyPhone1 = this.selectedQRCode?.url?.data?.companyPhone1 || '';
            this.companyPhone2 = this.selectedQRCode?.url?.data?.companyPhone2 || '';

            this.companyEmail1 = this.selectedQRCode?.url?.data?.companyEmail1 || '';
            this.companyEmail2 = this.selectedQRCode?.url?.data?.companyEmail2 || '';

            this.companyDescription = this.selectedQRCode?.url?.data?.companyDescription || '';
            this.companyFacebook = this.selectedQRCode?.url?.data?.companyFacebook || '';
            this.companyTwitter = this.selectedQRCode?.url?.data?.companyTwitter || '';
            this.companyLinkedIn = this.selectedQRCode?.url?.data?.companyLinkedIn || '';
            this.companyInstagram = this.selectedQRCode?.url?.data?.companyInstagram || '';
            this.companyYoutube = this.selectedQRCode?.url?.data?.companyYoutube || '';
            this.companyPinterest = this.selectedQRCode?.url?.data?.companyPinterest || '';
            this.companyEtsy = this.selectedQRCode?.url?.data?.companyEtsy || '';
            this.companyShopify = this.selectedQRCode?.url?.data?.companyShopify || '';
            this.companyTikTok = this.selectedQRCode?.url?.data?.companyTikTok || '';

            this.profileImage = {
                status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                image: this.selectedQRCode?.url?.data?.profile?.image,
            };
            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape || '';
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border || false;
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
        prettyURL(url: string) {
            return Utils.prettyURL(url);
        },
    },
});
