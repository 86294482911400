
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';
import Utils from '@/utils/Utils';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            couponName: '',
            couponCode: '',
            couponDiscount: '',
            couponEmail1: '',
            couponPhone1: '',
            couponExpires: '',
            couponDescription: '',
            couponWebsite: '',
            couponLink1: '',
            couponFinePrint: '',
            templateForegroundColor: '',
            templateFontColor: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
            buttonBackgroundColor: '',
            buttonBorderColor: '',
            buttonBorderType: '',
            buttonBorderRadius: '',
            buttonBorderWidth: '',
            buttonFontColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
        this.setButtonBackgroundColor();
        this.setButtonBorderRadius();
        this.setButtonBorderWidth();
        this.setButtonBorderType();
        this.setButtonBorderColor();
        this.setButtonFontColor();
    },
    methods: {
        onload() {
            this.couponName = this.selectedQRCode?.url?.data?.couponName || '';
            this.couponCode = this.selectedQRCode?.url?.data?.couponCode || '';
            this.couponDiscount = this.selectedQRCode?.url?.data?.couponDiscount || '';
            this.couponEmail1 = this.selectedQRCode?.url?.data?.couponEmail1 || '';
            this.couponPhone1 = this.selectedQRCode?.url?.data?.couponPhone1 || '';
            this.couponExpires = this.selectedQRCode?.url?.data?.couponExpires || '';
            this.couponDescription = this.selectedQRCode?.url?.data?.couponDescription || '';
            this.couponWebsite = this.selectedQRCode?.url?.data?.couponWebsite || '';
            this.couponLink1 = this.selectedQRCode?.url?.data?.couponLink1 || '';
            this.couponFinePrint = this.selectedQRCode?.url?.data?.couponFinePrint || '';

            if (this.selectedQRCode?.url?.data?.profile) {
                this.profileImage = {
                    status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                    image: this.selectedQRCode?.url?.data?.profile?.image,
                };
            } else {
                this.profileImage = {
                    status: 'deleted',
                    image: '',
                };
            }

            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape || 'circle-1';
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border || true;
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
        prettyURL(url: string) {
            return Utils.prettyURL(url);
        },
        setButtonBackgroundColor() {
            this.buttonBackgroundColor = ThemeUtils.getButtonBackgroundColor(this.selectedQRCode?.url);
        },
        setButtonFontColor() {
            this.buttonFontColor = ThemeUtils.getButtonFontColor(this.selectedQRCode?.url);
        },
        setButtonBorderType() {
            this.buttonBorderType = ThemeUtils.getButtonBorderType(this.selectedQRCode?.url);
        },
        setButtonBorderRadius() {
            this.buttonBorderRadius = ThemeUtils.getButtonBorderRadius(this.selectedQRCode?.url);
        },
        setButtonBorderWidth() {
            this.buttonBorderWidth = ThemeUtils.getButtonBorderWidth(this.selectedQRCode?.url);
        },
        setButtonBorderColor() {
            this.buttonBorderColor = ThemeUtils.getButtonBorderColor(this.selectedQRCode?.url);
        },
    },
});
