
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';

import Theme1 from '@/components/QRCode/DynamicPreviewer/PDFTheme/Themes/Theme1.vue';
import ThemeFooter from '@/components/ThemeFooter.vue';

export default defineComponent({
    name: 'PDF',
    components: { Theme1, ThemeFooter },
    data() {
        return {
            selectedLayout: 1,
            templateBackgroundColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    mounted() {
        this.setTheme();
    },
    methods: {
        setTheme() {
            this.selectedLayout = this.selectedQRCode?.url?.data?.theme?.layout || 1;
        },
    },
});
