import axios from 'axios';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import theLocalStorageUtils from '@/utils/LocalStorage';
import { LocalStorage } from '@/constants/LocalStorage';

export default abstract class BaseApiService {
    private baseUrl: string;
    private additionalHeaders: any = {};
    private includeAuthorization = true;

    axiosInstance = axios.create();

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;

        this.axiosInstance.interceptors.request.use(this.onRequest);
        this.axiosInstance.interceptors.response.use(this.onResponse, this.onResponseError);
    }

    onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        config.baseURL = this.baseUrl.toLowerCase();
        config.headers = this.buildHeaders();
        return config;
    };

    onResponse = (response: AxiosResponse): AxiosResponse => {
        this.resetAfterResponse();
        return response;
    };

    onResponseError = async (error: AxiosError): Promise<AxiosError> => {
        return error;
    };

    addHeaders(headers: any): void {
        this.additionalHeaders = { ...this.additionalHeaders, ...headers };
    }

    buildQuery(requestParams: any): string {
        let result = '';
        if (requestParams) {
            const keys = Object.keys(requestParams);
            for (const key of keys) {
                const trimmmed = `${requestParams[key]}`.trim();
                result += result.length === 0 ? '?' : '&';
                result += key + '=';
                result += encodeURIComponent(trimmmed);
                result = result.trim();
            }
        }
        return result;
    }

    addAcceptJsonHeader() {
        this.addHeaders({ accept: 'application/json' });
    }

    addContentTypeJsonHeader() {
        this.addHeaders({ 'Content-Type': 'application/json' });
    }

    setIncludeAuthorization(include: boolean): void {
        this.includeAuthorization = include;
    }

    private resetAfterResponse(): void {
        this.additionalHeaders = {};
    }

    private buildHeaders(): any {
        let headers = {};

        if (Object.keys(this.additionalHeaders).length > 0) {
            headers = { ...headers, ...this.additionalHeaders };
        }

        if (this.includeAuthorization) {
            const jwt = theLocalStorageUtils.get(LocalStorage.at);
            if (jwt) {
                const authHeader = {
                    Authorization: `Bearer ${jwt}`,
                };
                headers = { ...headers, ...authHeader };
            }
        }

        return headers;
    }
}
