import axios from 'axios';
import IWebConfig from '@/models/IWebConfig';

export default class WebConfig {
    private static instance: WebConfig;
    private configs: IWebConfig[] = [];

    private constructor() {}

    public static getInstance(): WebConfig {
        if (!this.instance) {
            this.instance = new WebConfig();
        }
        return this.instance;
    }

    async getConfig(): Promise<IWebConfig[]> {
        if (!this.haveConfig()) {
            this.configs = await this.getConfigFromFile();
        }
        return this.configs as IWebConfig[];
    }

    async refreshConfig(): Promise<void> {
        this.configs = await this.getConfigFromFile();
    }

    async getConfigByHost(hostname?: string): Promise<IWebConfig> {
        if (!hostname) {
            const url = new URL(window.location.href);
            const currentUrl = url || '';
            if (currentUrl) {
                hostname = (currentUrl as URL).hostname;
            }
        }

        const configs = await this.getConfig();
        for (const key in configs) {
            const config = configs[key];
            if (config.hostname?.toLowerCase() === hostname?.toLowerCase()) {
                return config;
            }
        }
        return {};
    }

    private haveConfig() {
        return this.configs && this.configs.length > 0;
    }

    private async getConfigFromFile(): Promise<IWebConfig[]> {
        const fileData = await await axios.get<any>('/web-config.json', {});
        return fileData.data;
    }
}
