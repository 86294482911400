
import { qrCodeStore } from '@/store';
import { storeWatcher } from '@/utils/StoreWatcher';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import _ from 'lodash';
import ThemeUtils from '@/utils/ThemeUtils';
import Utils from '@/utils/Utils';

export default defineComponent({
    name: 'Theme1',
    components: {},
    data() {
        return {
            appName: '',
            websiteUrl: '',
            appDescription: '',
            appGooglePlayLink: '',
            appAppleStoreLink: '',
            appWindowsStoreLink: '',
            appOtherLink: '',
            templateForegroundColor: '',
            templateFontColor: '',
            profileImage: {} as any,
            profileShape: '',
            profileShapeBorder: true,
            buttonBackgroundColor: '',
            buttonBorderColor: '',
            buttonBorderType: '',
            buttonBorderRadius: '',
            buttonBorderWidth: '',
            buttonFontColor: '',
        };
    },
    setup() {
        const settings = storeWatcher(qrCodeStore);
        const computedSettings = computed(() => settings.SelectedQRCode);
        const selectedQRCode = _.cloneDeep(computedSettings.value);

        return {
            settings,
            selectedQRCode,
        };
    },
    async mounted() {
        this.setTemplateForegroundColor();
        this.setTemplateFontColor();
        this.onload();
        this.hasProfileBorder();
        this.setButtonBackgroundColor();
        this.setButtonBorderRadius();
        this.setButtonBorderWidth();
        this.setButtonBorderType();
        this.setButtonBorderColor();
        this.setButtonFontColor();
    },
    methods: {
        onload() {
            this.appName = this.selectedQRCode?.url?.data?.appName || '';
            this.websiteUrl = this.selectedQRCode?.url?.data?.websiteUrl || '';
            this.appDescription = this.selectedQRCode?.url?.data?.appDescription || '';

            this.appGooglePlayLink = this.selectedQRCode?.url?.data?.appGooglePlayLink || '';
            this.appAppleStoreLink = this.selectedQRCode?.url?.data?.appAppleStoreLink || '';
            this.appWindowsStoreLink = this.selectedQRCode?.url?.data?.appWindowsStoreLink || '';

            this.appOtherLink = this.selectedQRCode?.url?.data?.appOtherLink || '';

            this.profileImage = {
                status: this.selectedQRCode?.url?.data?.profile?.image === '' ? 'deleted' : 'set',
                image: this.selectedQRCode?.url?.data?.profile?.image,
            };
            this.profileShape = this.selectedQRCode?.url?.data?.profile?.shape;
            this.profileShapeBorder = this.selectedQRCode?.url?.data?.profile?.border;
        },
        setTemplateForegroundColor() {
            this.templateForegroundColor = ThemeUtils.getTemplateForegroundColor(this.selectedQRCode?.url);
        },
        setTemplateFontColor() {
            this.templateFontColor = ThemeUtils.getTemplateFontColor(this.selectedQRCode?.url);
        },
        hasProfileBorder() {
            return this.profileShapeBorder ? 'profile-shape-border' : '';
        },
        prettyURL(url: string) {
            return Utils.prettyURL(url);
        },
        setButtonBackgroundColor() {
            this.buttonBackgroundColor = ThemeUtils.getButtonBackgroundColor(this.selectedQRCode?.url);
        },
        setButtonFontColor() {
            this.buttonFontColor = ThemeUtils.getButtonFontColor(this.selectedQRCode?.url);
        },
        setButtonBorderType() {
            this.buttonBorderType = ThemeUtils.getButtonBorderType(this.selectedQRCode?.url);
        },
        setButtonBorderRadius() {
            this.buttonBorderRadius = ThemeUtils.getButtonBorderRadius(this.selectedQRCode?.url);
        },
        setButtonBorderWidth() {
            this.buttonBorderWidth = ThemeUtils.getButtonBorderWidth(this.selectedQRCode?.url);
        },
        setButtonBorderColor() {
            this.buttonBorderColor = ThemeUtils.getButtonBorderColor(this.selectedQRCode?.url);
        },
    },
});
