import IURL from '@/models/IURL';

export default {
    uuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },
    parseURL(url: string) {
        try {
            const obj: IURL = new URL(url);
            return obj;
        } catch (e) {
            const www = `www.`;
            if (!url.includes('www')) {
                url = `${www}${url}`;
            }

            //checking for top level domain. ie. .com
            const com = `.com`;
            const pattern = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
            if (!pattern.test(url)) {
                url = `${url}${com}`;
            }

            const https = `https://`;
            if (!url.includes('http')) {
                url = `${https}${url}`;
            }

            const obj: IURL = new URL(url);
            return obj;
        }
    },
    prettyURL(url: string): string {
        try {
            const obj: IURL = new URL(url);
            let prettyUrl = obj.hostname || url;
            prettyUrl = prettyUrl.replace('www.', '');
            return prettyUrl;
        } catch (e) {
            return url;
        }
    },
};
