import ITinyUrl from './ITinyUrl';

export default interface QRCode {
    qr?: any;
    url: ITinyUrl;
}

export function newQRCode(): QRCode {
    const response: any = {
        qr: {},
        url: {} as ITinyUrl,
    };

    return response;
}
