import { createStore } from 'vuex';
import { getModule } from 'vuex-module-decorators';

import DetectBrowserStore from '@/store/DetectBrowser';
import QRCodeStore from '@/store/QRCode';

export const store = createStore({
    modules: {
        qrcode: QRCodeStore,
        detectbrowser: DetectBrowserStore,
    },
    strict: process.env.NODE_ENV !== 'production',
});

const qrCodeStore = getModule(QRCodeStore, store);
const detectBrowserStore = getModule(DetectBrowserStore, store);

export { qrCodeStore, detectBrowserStore };
