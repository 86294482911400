import { DateTime, Settings } from 'luxon';

export default {
    setTimeZone(timeZone: string): boolean {
        const checkDateTime = DateTime.local().setZone(timeZone);
        if (!checkDateTime.isValid) {
            return false;
        }

        if (Settings.defaultZone !== timeZone) {
            Settings.defaultZone = timeZone;
        }

        return true;
    },
    getLuxonFormat(dateTime: any): DateTime {
        return convertToLuxon(dateTime);
    },
    getUserTimezoneName(): string {
        try {
            return DateTime.now().toLocal().zoneName;
        } catch (e) {
            return '';
        }
    },
    getUnixTime(dateTime: any): string {
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toUnixInteger();
        } catch (e) {
            return '';
        }
    },
    compareDateToNow(dateTime: any): boolean {
        try {
            const now: DateTime = convertToLuxon(new Date());
            dateTime = convertToLuxon(dateTime);

            const d1 = this.getUnixTime(dateTime);
            const d2 = this.getUnixTime(now);
            return d2 > d1;
        } catch (e) {
            return false;
        }
    },
    shortDate(dateTime: any): string {
        // 10/14/1983
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toFormat('LL/dd/y');
        } catch (e) {
            return '';
        }
    },
    shortDateReverse(dateTime: any): string {
        // 1983/10/14
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toFormat('y/LL/dd');
        } catch (e) {
            console.log(e);
            return '';
        }
    },
    shortTime(dateTime: any): string {
        // 1:30 PM
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
        } catch (e) {
            return '';
        }
    },
    shortDateTime(dateTime: any): string {
        // 10/14/1983, 1:30 PM
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
        } catch (e) {
            return '';
        }
    },
    fullDateWithWeekday(dateTime: any): string {
        // Friday, October 14, 1983
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toLocaleString(DateTime.DATE_HUGE);
        } catch (e) {
            return '';
        }
    },
    fullDateWithTimeAbbreviatedOffset(dateTime: any): string {
        // October 14, 1983 at 1:30 PM EDT
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.toLocaleString(DateTime.DATETIME_FULL);
        } catch (e) {
            return '';
        }
    },
    convertToISO(dateTime: any, format: any = {}): string {
        try {
            dateTime = convertToLuxon(dateTime);
            return dateTime.set({ milliseconds: 0 }).toISO(format);
        } catch (e) {
            return '';
        }
    },
    getTimeAbbreviatedFromTimezoneName(dateTime: any, timezone: string): string {
        // CST
        try {
            dateTime = convertToLuxon(dateTime);
            return DateTime.fromISO(dateTime, { zone: `${timezone}` }).toFormat('ZZZZ');
        } catch (e) {
            return '';
        }
    },
};

function convertToLuxon(dateTime: any): DateTime {
    if (typeof dateTime === 'string') {
        return DateTime.fromISO(dateTime);
    } else if (dateTime instanceof Date) {
        return DateTime.fromJSDate(dateTime);
    }
    return dateTime;
}

// This does not work below - tech debt
// https://stackoverflow.com/questions/67988216/luxon-datetime-adding-type-for-extension
// ie. dateTime.convertToLuxon.toLocaleString(DateTime.DATE_SHORT)
declare module 'luxon/src/datetime' {
    export interface DateTime {
        convertToLuxon(dateTime: any): DateTime;
    }
}

DateTime.prototype.convertToLuxon = function (dateTime: any): DateTime {
    if (typeof dateTime === 'string') {
        return DateTime.fromISO(dateTime);
    } else if (dateTime instanceof Date) {
        return DateTime.fromJSDate(dateTime);
    }
    return dateTime;
};
