export default {
    getList() {
        const list = [
            {
                platform: 'facebook',
                label: 'Facebook',
                icon: 'fa-brands fa-facebook',
            },
            {
                platform: 'youtube',
                label: 'YouTube',
                icon: 'fa-brands fa-youtube',
            },
            {
                platform: 'whatsapp',
                label: 'WhatsApp',
                icon: 'fa-brands fa-whatsapp',
            },
            {
                platform: 'instagram',
                label: 'Instagram',
                icon: 'fa-brands fa-instagram',
            },
            {
                platform: 'wechat',
                label: 'WeChat',
                icon: 'fa-brands fa-weixin',
            },
            {
                platform: 'tiktok',
                label: 'TikTok',
                icon: 'fa-brands fa-tiktok',
            },
            {
                platform: 'messenger',
                label: 'Messenger',
                icon: 'fa-brands fa-facebook-messenger',
            },
            {
                platform: 'Tencent QQ',
                label: 'QQ',
                icon: 'fa-brands fa-qq',
            },
            {
                platform: 'weibo',
                label: 'Weibo',
                icon: 'fa-brands fa-weibo',
            },
            {
                platform: 'snapchat',
                label: 'Snapchat',
                icon: 'fa-brands fa-snapchat',
            },
            {
                platform: 'telegram',
                label: 'Telegram',
                icon: 'fa-brands fa-telegram',
            },
            {
                platform: 'pinterest',
                label: 'Pinterest',
                icon: 'fa-brands fa-pinterest-p',
            },
            {
                platform: 'twitter',
                label: 'Twitter',
                icon: 'fa-brands fa-twitter',
            },
            {
                platform: 'reddit',
                label: 'Reddit',
                icon: 'fa-brands fa-reddit-alien',
            },
            {
                platform: 'linkedin',
                label: 'LinkedIn',
                icon: 'fa-brands fa-linkedin-in',
            },
            {
                platform: 'quora',
                label: 'Quora',
                icon: 'fa-brands fa-quora',
            },
            {
                platform: 'viber',
                label: 'Viber',
                icon: 'fa-brands fa-viber',
            },
            {
                platform: 'line',
                label: 'LINE',
                icon: 'fa-brands fa-line',
            },
            {
                platform: 'likee',
                label: 'Likee',
                icon: 'fa-solid fa-heart',
            },
            {
                platform: 'discord',
                label: 'Discord',
                icon: 'fa-brands fa-discord',
            },
            {
                platform: 'twitch',
                label: 'Twitch',
                icon: 'fa-brands fa-twitch',
            },
            {
                platform: 'stackexchange',
                label: 'StackExchange',
                icon: 'fa-brands fa-stack-exchange',
            },
            {
                platform: 'github',
                label: 'GitHub',
                icon: 'fa-brands fa-github',
            },
            {
                platform: 'yelp',
                label: 'Yelp',
                icon: 'fa-brands fa-yelp',
            },
            {
                platform: 'vk.com',
                label: 'vk',
                icon: 'fa-brands fa-vk',
            },
            {
                platform: 'stumbleupon',
                label: 'StumbleUpon',
                icon: 'fa-brands fa-stumbleupon',
            },
            {
                platform: 'etsy',
                label: 'Etsy',
                icon: 'fa-brands fa-etsy',
            },
            {
                platform: 'shopify',
                label: 'Shopify',
                icon: 'fa-brands fa-shopify',
            },
        ];

        list.sort((a, b) => a.platform.localeCompare(b.platform));
        list.unshift({
            platform: 'none',
            label: 'None',
            icon: 'fa-solid fa-earth-americas',
        });

        return list;
    },
    getIcon(str: string): string {
        const list = this.getList();
        for (let i = 0; i < list.length; i++) {
            const platform = list[i].platform;
            const icon = list[i].icon;

            if (str === platform) {
                return icon;
            }
        }
        return 'fa-solid fa-earth-americas';
    },
    getLabel(str: string): string {
        const list = this.getList();
        for (let i = 0; i < list.length; i++) {
            const platform = list[i].platform;
            const label = list[i].label;

            if (str === platform) {
                return label;
            }
        }
        return 'Url';
    },
};
