import ITinyUrl, { newTinyUrl } from '@/models/ITinyUrl';
import IWebConfig from '@/models/IWebConfig';
import ApiBase from '@/services/ApiBaseService';

export default class TinyUrl extends ApiBase {
    constructor(webEnvConfig: IWebConfig) {
        super(webEnvConfig.urls?.webApi ?? '');
    }

    async fetchTinyUrl(tinyCode: string): Promise<ITinyUrl> {
        let result: ITinyUrl = newTinyUrl();
        this.setIncludeAuthorization(false);
        this.addAcceptJsonHeader();

        const response = await this.axiosInstance.get(`api/v1/tiny-url/fetch?code=${tinyCode}`);
        if (response.status === 200) {
            result = response.data || {};
        }
        return result;
    }
}
